import * as Sentry from "@sentry/nuxt";

Sentry.init({
  dsn: "https://4574038df1b1bdde71e64590b0ce5301@sentry.myvision.dev/4",
  release: "galaxyservice-front@" + process.env.npm_package_version,
  tracePropagationTargets: [/^https:\/\/api\.galaxyturbo\.co\/api\/galaxyturbo/,/^https:\/\/gvcrm\.liara\.run\/api/],
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
        maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
      networkDetailAllowUrls: [window.location.origin,'https://gvcrm.liara.run/api/member/authenticate'],
    }),
    Sentry.httpClientIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
